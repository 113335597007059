export default function cetientImageLoader({ src, width, quality }) {
  const cdnUrl = process.env.NEXT_PUBLIC_AWS_IMAGES_CLOUDFRONT_URL;
  if (!cdnUrl || !src.startsWith(cdnUrl)) {
    return src;
  }

  const path = src.replace(`${cdnUrl}/`, "");

  const imageUrl = [
    cdnUrl,
    width && `fit-in/${width}x0`,
    quality && `filters:quality`,
    encodeURI(path),
  ].filter( value => !!value );

  return imageUrl.join("/");
}
