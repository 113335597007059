'use client';

import { classNames } from "@/lib/ui/utils";
import Link from "next/link";
import { useCallback } from "react";

export type SmallButtonProps = {
  caption: string,
  href?: string,
  target?: "_blank",
  onClick?: () => void,

  isSaving?: boolean,
  isDisabled?: boolean,

  className?: string,
};

export default function SmallButton({caption, href, target, onClick, isSaving, isDisabled, className}: SmallButtonProps) {

  const buttonClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isDisabled && onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  }, [isDisabled, onClick]);

  return (
    <Link
      href={isDisabled || isSaving ? "#" : href || "#"}
      target={target}
      onClick={(event) => { buttonClick(event); }}
      className={classNames(
        "inline-flex whitespace-nowrap no-underline px-2.5 py-1",
        "text-xs font-semibold",
        "hover:bg-button-hover",
        "rounded shadow-sm ring-1 ring-inset ring-standard-ring",
        isDisabled || isSaving ? "text-disabled-text bg-button-disabled" : "text-standard-text bg-page-background",
        isSaving ? "cursor-progress" : "",
        className || '',
      )}
    >
      {isSaving && (
        <svg className="animate-spin -ml-1 mr-1 h-4 w-4 text-secondary-text" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      {caption}
    </Link>
  );
}
