import EventEmitter from 'events';

export enum NotificationType {
  Success,
  Error,
}

export type NotificationMessage = {
  type: NotificationType,
  title: string,
  description: string,
}

const notificationBus = new EventEmitter();

export const uiEventsBus = new EventEmitter();

export function showNotification(message: NotificationMessage) {
  notificationBus.emit("newNotification", message);
}

export function subscribeToNotifications(callback: (message: NotificationMessage) => void ) {
  return notificationBus.on("newNotification", callback);
}

export function unsubscribeFromNotifications(callback: (message: NotificationMessage) => void) {
  notificationBus.removeListener("newNotification", callback);
}
