'use client';

import { defaultPhotoUrl } from "@/lib/experts/expert";
import { CurrentUserNavMode } from "@/lib/ui/types";
import { classNames } from "@/lib/ui/utils";
import { User, UserRole } from "@/lib/users/user";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import Link from "next/link";
import ImpersonateLink from "./impersonate-link";


type Props = {
  user: User,
  impersonatedUserId?: string | null,
  mode: CurrentUserNavMode,
  hasDashboardLink?: boolean,
};

export default function ProfileDropdown({ user, impersonatedUserId, mode, hasDashboardLink }: Props) {

  const userNavigation = [
  ];

  if (hasDashboardLink) {
    userNavigation.push({ name: 'Research Dashboard', href: '/research' });
  }

  if (user.role == UserRole.admin) {
    userNavigation.push({ name: 'Admin', href: '/admin/users' });
  }
  userNavigation.push({ name: 'Sign Out', href: `/auth/signout?callbackUrl=${encodeURIComponent('/')}` });


  return (
    <Menu>
      <MenuButton className={
        classNames(
          mode == "picture" ? "sm:-mx-4 sm:px-4" :"-mx-4 px-4",
          "flex-1 flex items-center -my-2 py-2 sm:hover:bg-list-item-hover sm:hover:rounded-md max-sm:active:bg-list-item-hover max-sm:active:rounded-md",
        )}
      >
        <div className="w-8 h-8 relative overflow-hidden flex-none">
          <Image className="rounded-full object-cover" sizes="8rem" priority={true} src={defaultPhotoUrl()} alt={user.name!} fill={true} />
        </div>
        <span className={
          classNames(
            mode == "picture" ? "hidden" : "flex",
            "sm:flex sm:items-center",
          )}
        >
          <span className={classNames(
            "ml-2 text-sm font-semibold",
            impersonatedUserId ? "text-red-text" : "",
          )}>
            {user.name}
          </span>
          <ChevronDownIcon className="ml-2 h-5 w-5 text-tertiary-text" aria-hidden="true" />
        </span>
      </MenuButton>
      <MenuItems
        anchor="top start"
        transition
        className="rounded-md bg-input-background py-2 shadow-lg ring-1 ring-standard-ring focus:outline-none min-w-[var(--button-width)] origin-top-right transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 z-40"
      >
        {userNavigation.map((item) => (
          <MenuItem key={item.name}>
            <Link
              href={item.href}
              prefetch={false}
              className="block px-5 py-2 mx--5 my--2 text-sm leading-6 font-semibold whitespace-nowrap data-[focus]:bg-list-item-current"
            >
              {item.name}
            </Link>
          </MenuItem>
        ))}
        {typeof impersonatedUserId !== 'undefined' && (
          <MenuItem key="impersonate">
            <div className="data-[focus]:bg-list-item-current">
              <ImpersonateLink action="logout" />
            </div>
          </MenuItem>
        )}
      </MenuItems>
    </Menu>
  );
}
