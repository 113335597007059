import { Navigation } from "./types";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function updateCurrentNavigationItem(navigation: Navigation, currentPath: string): Navigation {
  return navigation.map((section) => {
    section.items.map((item) => {
      item.current = item.href == currentPath;
      return item;
    });
    return section;
  });
}

export function transformEmptyFormValuesToNulls(v: unknown) {
  if (typeof v === 'string' && v === '') {
    return null;
  }
  return v;
}

export function getRefCheckPrompt(opinion: { caseName: string, citations: string[] }): string {
  return `Check treatment of "${opinion.caseName}, ${opinion.citations[0]}"`;
}
