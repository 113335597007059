import { Model } from "@/lib/db/model";

export enum UserRole {
  admin = "admin",
  user = "user",
}
export interface User extends Model {
  name: string,
  email: string,
  phoneNumber?: string,
  lastLoginAt?: Date,
  emailVerified: Date,
  role: UserRole,
}

export function splitName(name: string | null | undefined): string[] {
  return name?.trim().split(/\s+/) ?? [];
}
