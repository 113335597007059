import { CurrentUser } from "@/lib/auth/get-current-user";
import { Model } from "@/lib/db/model";
import { cdnUrl } from "@/lib/ui/cdn";
import { splitName } from "@/lib/users/user";

export interface Expert extends Model {
  username: string,
  firstName: string,
  lastName: string,
  tagline: string,
  email?: string,
  phoneNumber?: string,
  location: string,
  createdBy: string,
  companyId: string,

  intro?: string,
  followUp?: string,

  link1?: string,
  link2?: string,
  link3?: string,
  link4?: string,

  photoUrl?: string,

  tools?: string[]
}

export function newExpert(user: CurrentUser) {
  const [firstName, ...lastNames] = splitName(user.name);
  return {
    id: '00000000-0000-0000-0000-000000000000',
    firstName: firstName,
    lastName: lastNames.join(" "),
    tagline: 'Your tagline',
    location: 'Your location',
    photoUrl: defaultPhotoUrl(),
  } as Expert;
}

export function defaultPhotoUrl(): string {
  return cdnUrl('/common/silhouette.webp');
}

export function generateUsername(expert: Expert): string {
  return `${expert.firstName} ${expert.lastName}`.toLowerCase().trim().replace(/\s+/g,'-');
}

export const expertProfileFormAllowedFields = [
  "firstName", "lastName", "tagline", "location", "email", "phoneNumber", "link1", "link2", "link3", "link4", "intro", "followUp", "tools"
];
