import { NotificationType, showNotification } from '@/lib/ui/notifications';
import { H } from '@highlight-run/next/client';
import { AxiosResponse } from "axios";
import { useState } from 'react';

type R =
  | Response
  | AxiosResponse;

// user friendly fetch:
// - shows success/fail notifications
// - handles any errors
// - manages isSaving state
export default function useUserFriendlyFetch() {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const userFriendlyFetch = async (fetchFunction: () => Promise<R>) => {
    try {
      setIsSaving(true);
      const response = await fetchFunction();
      if (response.status === 200) {
        showNotification({ type: NotificationType.Success, title: 'Success', description: response.statusText });
      } else {
        showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: response.statusText });
      }
    } catch (error) {
      if (error instanceof Error) H.consumeError(error);
      showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: 'Please try again' });
    } finally {
      setIsSaving(false);
    }
  };

  return { isSaving, userFriendlyFetch };
}
