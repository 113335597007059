'use client';
import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { NotificationMessage, NotificationType, subscribeToNotifications, unsubscribeFromNotifications } from '@/lib/ui/notifications';

export default function Notification() {
  const [show, setShow] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();

  useEffect(() => {
    const callback = (message: NotificationMessage) => {
      setNotificationMessage(message);
      setShow(true);
    };
    subscribeToNotifications(callback);

    return () => {
      unsubscribeFromNotifications(callback);
    };
  }, []);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => setShow(false), 4000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [show]);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-x-0 p-6 z-50"
      >
        <div className="flex flex-col items-center sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto max-w-sm overflow-hidden rounded-lg bg-input-background shadow-lg ring-1 ring-standard-ring">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    { notificationMessage?.type === NotificationType.Success && (
                      <CheckCircleIcon className="h-6 w-6 text-green-text" aria-hidden="true" />
                    )}
                    { notificationMessage?.type === NotificationType.Error && (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-text" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 flex-1 pt-0.5">
                    <p className="text-sm font-medium">{notificationMessage?.title}</p>
                    <p className="mt-1 text-sm text-secondary-text">{notificationMessage?.description}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md text-tertiary-text hover:text-standard-text focus:outline-none focus:ring-2 focus:ring-focused-ring focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
