'use client';
import { classNames } from "@/lib/ui/utils";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function SignInLink({mode}: {mode: "picture" | "nameAndPicture"}) {
  const pathname = usePathname();

  return (
    <Link
      href={`/auth/signin?callbackUrl=${encodeURIComponent(pathname)}`}
      prefetch={false}
      className={classNames(
        mode == "picture" ? "sm:-mx-4 sm:px-4" : "-mx-4 px-4 flex-1",
        "group flex items-center gap-x-2 rounded-md py-2 -my-2 text-sm leading-6 font-semibold whitespace-nowrap sm:hover:bg-list-item-hover sm:hover:rounded-md max-sm:active:bg-list-item-current max-sm:active:rounded-md",
      )}
    >
      <UserCircleIcon className="h-8 w-8 shrink-0" />
      Sign In
    </Link>
  );
}
