'use client';

import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import SmallButton from "./small-button";
import useUserFriendlyFetch from "@/lib/ui/user-friendly-fetch";

export default function ImpersonateLink(props: {action: "login" | "logout", userId?: string, sessionId?: string}) {
  const [action] = useState(props.action);
  const [userId] = useState(props.userId);
  const [sessionId] = useState(props.sessionId);
  const router = useRouter();

  const { isSaving, userFriendlyFetch } = useUserFriendlyFetch();
  const impersonate = useCallback(async () => {
    await userFriendlyFetch(async () => {
      const response = await fetch(`/api/admin/impersonate`, {
        method: action == "login" ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          sessionId,
        }),
      });
      if (response.ok) {
        router.push(action == "login" ? "/research" : "/admin/users");
        router.refresh();
      }
      return response;
    });
  }, [action, router, userId, sessionId, userFriendlyFetch]);

  return (
    <>
      { action == "login" && (
        <SmallButton caption="Log In As" onClick={impersonate} isSaving={isSaving} />
      )}
      { action == "logout" && (
        <a href="#" onClick={(event) => { impersonate(); event.preventDefault(); event.stopPropagation(); }} className="text-red-text block px-5 py-2 mx--5 px--2 text-sm leading-6 font-semibold">
          Back to Your Account
        </a>
      )}
    </>
  );
}
