import s3Client from "@/lib/clients/s3";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

// url should be relative to the site root
export function cdnUrl(url: string): string {
  const path = url.startsWith("/") ? url : `/${url}`;

  if (process.env.NEXT_PUBLIC_AWS_IMAGES_CLOUDFRONT_URL) {
    return `${process.env.NEXT_PUBLIC_AWS_IMAGES_CLOUDFRONT_URL}${path}`;
  }
  return path;
}

export async function imageUrl(s3key: string) {
  let url;
  if (process.env.NEXT_PUBLIC_AWS_IMAGES_CLOUDFRONT_URL) {
    // CDN is configured, serve from CDN
    return `${process.env.NEXT_PUBLIC_AWS_IMAGES_CLOUDFRONT_URL}/${s3key}`;
  } else {
    // no CDN configured, for example, on development
    // just serve directly from S3
    const command = new GetObjectCommand({
      Bucket: process.env.AWS_IMAGES_BUCKET_NAME!,
      Key: s3key,
    });

    url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
  }

  return url;
}
